import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGem } from "@fortawesome/free-regular-svg-icons"

import SEO from "../components/SEO"
import styles from "../css/about-css-modules.module.css"
import Banner from "../components/Banner"
import Layout from "../components/layout/Layout"
import CustomCarousel from "../components/Carousel"
import { ClientCard } from "../components/Card"
import { Row, ResponsiveRow, Col, SectionWrapper, SectionInnerWrapper, CommonContentWrapper, CommonContentDivider, AlignRight, SectionHeader, SectionSubHeader } from '../components/Wrapper'

import { clients, awards } from "../data/customer-data"
import AwardImage from "../images/test/award1.png"
import CompanyImage from "../images/test/company.jpg"

const BlogPage = ({ data }) => {
    return (
        <Layout>
            <SEO
                title={`All Ins Agency - Blog`}
                description={`All Ins Agency`}
            />
            <Banner heading="OUR BLOG" subHeading="IN THE NEWS" trail="Blog" trailLink="/blog" />
            <SectionWrapper>
                <SectionInnerWrapper>
                    
                </SectionInnerWrapper>
            </SectionWrapper>
        </Layout >
    )
}

export default BlogPage
