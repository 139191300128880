export default [
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    content:
      'Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Sit amet consectetur adipiscing elit duis.',
    customerName: 'Jane Doe',
    customerTitle: 'Director of Research and Data',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    content:
      'Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Sit amet consectetur adipiscing elit duis.',
    customerName: 'John Doe',
    customerTitle: 'Director of Research and Data',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    content:
      'Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Sit amet consectetur adipiscing elit duis.',
    customerName: 'Jane Smith',
    customerTitle: 'Director of Research and Data',
    customerImage: 'https://placeimg.com/150/150/people'
  }
];

export const testimonials = [
  {
    quote: "Lorem ipsum dolor sit consctetur adipiscing elit. Se Integer lore quam, adipiscing in condimentum tristique vel, eleifend sed turpis.",
    name: "John Doe",
    position: "Agent, Alico Agency 1"
  },
  {
    quote: "Lorem ipsum dolor sit consctetur adipiscing elit. Se Integer lore quam, adipiscing in condimentum tristique vel, eleifend sed turpis.",
    name: "John Doe",
    position: "Agent, Alico Agency 2"
  },
  {
    quote: "Lorem ipsum dolor sit consctetur adipiscing elit. Se Integer lore quam, adipiscing in condimentum tristique vel, eleifend sed turpis.",
    name: "John Doe",
    position: "Agent, Alico Agency 3"
  },
  {
    quote: "Lorem ipsum dolor sit consctetur adipiscing elit. Se Integer lore quam, adipiscing in condimentum tristique vel, eleifend sed turpis.",
    name: "John Doe",
    position: "Agent, Alico Agency 4"
  }
];

export const productMenu = [
  {
    category: "Personal",
    items: [
      {
        id: "1",
        title: "Motor Insurance",
        imageUrl: "",
      },
      {
        id: "2",
        title: "Travel Insurance",
        imageUrl: "",
      },
      {
        id: "3",
        title: "Overseas Student Assist",
        imageUrl: "",
      },
      {
        id: "4",
        title: "Personal Accident Insurance",
        imageUrl: "",
      },
      {
        id: "5",
        title: "Domestic Maid Insurance",
        imageUrl: "",
      },
      {
        id: "6",
        title: "Golf Insurance",
        imageUrl: "",
      },
      {
        id: "7",
        title: "Personal Mobility Guard",
        imageUrl: "",
      },
      {
        id: "8",
        title: "Pet Insurance",
        imageUrl: "",
      },
    ]
  },
  {
    category: "Commercial",
    items: [
      {
        id: "9",
        title: "Work Injury Compensation (WIC)",
        imageUrl: "",
      },
      {
        id: "10",
        title: "Business Package (SME)",
        imageUrl: "",
      },
      {
        id: "11",
        title: "Foreign Worker Medical Insurance (FWMI)",
        imageUrl: "",
      },
      {
        id: "12",
        title: "Business Interruption (BI)",
        imageUrl: "",
      },
      {
        id: "13",
        title: "Fire Insurance",
        imageUrl: "",
      },
      {
        id: "14",
        title: "Money - Premise and Transit",
        imageUrl: "",
      },
      {
        id: "15",
        title: "Fidelity Guarantee (FG)",
        imageUrl: "",
      },
      {
        id: "16",
        title: "All Risks (Machinery / Equipment)",
        imageUrl: "",
      }
    ]
  },
  {
    category: "Financial",
    items: [
      {
        id: "17",
        title: "Professional Indemnity (PI)",
        imageUrl: "",
      },
      {
        id: "18",
        title: "Directors and Officers (D&O)",
        imageUrl: "",
      },
      {
        id: "19",
        title: "Cyber",
        imageUrl: "",
      }
    ]
  },
  {
    category: "Health",
    items: [
      {
        id: "20",
        title: "Health",
        imageUrl: "",
      }
    ]
  }
];

// faCar, faPlane, faUserGraduate, faUserShield, 

export const allProducts = [
  {
    id: "1",
    category: "Personal",
    title: "Motor Insurance",
    imageUrl: "",
    icon: "car"
  },
  {
    id: "21",
    category: "Personal",
    title: "Home Insurance",
    imageUrl: "",
    icon: "home"
  },
  {
    id: "2",
    category: "Personal",
    title: "Travel Insurance",
    imageUrl: "",
    icon: "plane"
  },
  {
    id: "3",
    category: "Personal",
    title: "Overseas Student Assist",
    imageUrl: "",
    icon: "user-graduate"
  },
  {
    id: "4",
    category: "Personal",
    title: "Personal Accident Insurance",
    imageUrl: "",
    icon: "user-shield"
  },
  {
    id: "5",
    category: "Personal",
    title: "Domestic Maid Insurance",
    imageUrl: "",
    icon: "broom"
  },
  {
    id: "6",
    category: "Personal",
    title: "Golf Insurance",
    imageUrl: "",
    icon: "golf-ball"
  },
  {
    id: "7",
    category: "Personal",
    title: "Personal Mobility Guard",
    imageUrl: "",
    icon: "biking"
  },
  {
    id: "8",
    category: "Personal",
    title: "Pet Insurance",
    imageUrl: "",
    icon: "dog"
  },
  {
    id: "9",
    category: "Commercial",
    title: "Work Injury Compensation (WIC)",
    imageUrl: "",
    icon: "building"
  },
  {
    id: "10",
    category: "Commercial",
    title: "Business Package (SME)",
    imageUrl: "",
    icon: "briefcase"
  },
  {
    id: "11",
    category: "Commercial",
    title: "Foreign Worker Medical Insurance (FWMI)",
    imageUrl: "",
    icon: "hard-hat"
  },
  {
    id: "12",
    category: "Commercial",
    title: "Business Interruption (BI)",
    imageUrl: "",
    icon: "user-tie"
  },
  {
    id: "13",
    category: "Commercial",
    title: "Fire Insurance",
    imageUrl: "",
    icon: "fire-alt"
  },
  {
    id: "14",
    category: "Commercial",
    title: "Money - Premise and Transit",
    imageUrl: "",
    icon: "hand-holding-usd"
  },
  {
    id: "15",
    category: "Commercial",
    title: "Fidelity Guarantee (FG)",
    imageUrl: "",
    icon: "user-shield"
  },
  {
    id: "16",
    category: "Commercial",
    title: "All Risks (Machinery / Equipment)",
    imageUrl: "",
    icon: "wrench"
  },
  {
    id: "17",
    category: "Financial",
    title: "Professional Indemnity (PI)",
    imageUrl: "",
    icon: "user-tie"
  },
  {
    id: "18",
    category: "Financial",
    title: "Directors and Officers (D&O)",
    imageUrl: "",
    icon: "user-shield"
  },
  {
    id: "19",
    category: "Financial",
    title: "Cyber",
    imageUrl: "",
    icon: "wifi"
  },
  {
    id: "20",
    category: "Health",
    title: "Health",
    imageUrl: "",
    icon: "hand-holding-heart"
  }
];

export const productCategory = [
  {
    id: "1",
    category: "Personal",
  },
  {
    id: "2",
    category: "Commercial",
  },
  {
    id: "3",
    category: "Financial",
  },
  {
    id: "4",
    category: "Health",
  }
];

export const menu = [
  {
    title: "Home",
    link: "/",
    showList: false
  }, 
  {
    title: "About",
    link: "/about",
    showList: false
  }, 
  {
    title: "Products",
    link: "/products",
    showList: false,
    showTable: true,
    subMenu: [...productMenu]
  }, 
  {
    title: "Quote",
    link: "/enquiry",
    showList: true,
    subMenu: null,
    // subMenu: [
    //   {
    //     title: "Enquiry",
    //     link: "/enquiry"
    //   },
    //   {
    //     title: "FAQ",
    //     link: "/faq"
    //   }
    // ]
  }, 
  {
    title: "Contact",
    link: "/contact",
    showList: false
  }, 
  // {
  //   title: "Updates",
  //   link: null,
  //   showList: true,
  //   subMenu: [
  //     {
  //       title: "News",
  //       link: "/news"
  //     },
  //     {
  //       title: "Blog",
  //       link: "/blog"
  //     }
  //   ]
  // }
];

export const clients = [
  {
    logoUrl: ""
  },
  {
    logoUrl: ""
  },
  {
    logoUrl: ""
  },
  {
    logoUrl: ""
  },
  {
    logoUrl: ""
  },
  {
    logoUrl: ""
  },
  {
    logoUrl: ""
  },
  {
    logoUrl: ""
  },
  {
    logoUrl: ""
  },
  {
    logoUrl: ""
  },
  {
    logoUrl: ""
  },
  {
    logoUrl: ""
  },
  {
    logoUrl: ""
  }
];

export const awards = [
  {
    logoUrl: ""
  },
  {
    logoUrl: ""
  },
  {
    logoUrl: ""
  },
  {
    logoUrl: ""
  }
];

export const faq = [
  {
    questeion: "About MetLife",
    answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.",
    link: "https://www.tokiomarine.com/sg/en/personal/resources/faq.html",
    important: false
  },
  {
    questeion: "Life Insurance",
    answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.",
    link: "https://www.tokiomarine.com/sg/en/personal/resources/faq.html",
    important: true
  },
  {
    questeion: "Accident & Health Insurance",
    answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.",
    link: "https://www.tokiomarine.com/sg/en/personal/resources/faq.html",
    important: false
  },
  {
    questeion: "Insurance through your Employer",
    answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.",
    link: "https://www.tokiomarine.com/sg/en/personal/resources/faq.html",
    important: false
  },
  {
    questeion: "Am I eligible for insurance?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.",
    link: "https://www.tokiomarine.com/sg/en/personal/resources/faq.html"
  },
  {
    questeion: "Can I afford insurance?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.",
    link: "https://www.tokiomarine.com/sg/en/personal/resources/faq.html",
    important: false
  },
  {
    questeion: "How do I apply for insurance?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.",
    link: "https://www.tokiomarine.com/sg/en/personal/resources/faq.html",
    important: false
  },
  {
    questeion: "How do I manage my policy?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.",
    link: "https://www.tokiomarine.com/sg/en/personal/resources/faq.html",
    important: false
  },
  {
    questeion: "How do I make Premium Payment?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.",
    link: "https://www.tokiomarine.com/sg/en/personal/resources/faq.html",
    important: false
  },
  {
    questeion: "How do I make a claim?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet.",
    link: "https://www.tokiomarine.com/sg/en/personal/resources/faq.html",
    important: false
  }
];

export const contactNumber = "+65 9999 8888";
export const contactAddress1 = "22 Sin Ming Lane";
export const contactAddress2 = "#05-78 Midview City"
export const contactPostalCode = "Singapore 573969";
export const contactEmail = "allins@cyder.com.sg";
export const contactWebsite = "www.allinsagency.com";